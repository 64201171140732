<script>
/* eslint-disable */

export default {
  name: 'Bi',
  provide: function () {
    return {
      dashboard: this
    }
  },
  mixins: [],
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  watch: {},
  beforeDestroy() {
  },
  methods: {},
  meta: {
    title: 'Business Intelligence (BI)',
    name: 'Bi'
  }
}
</script>

<template>
  <div class="flex col-grow wrapper-md dash-content erp-dash-content">
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <div class="wrapper-sm">
            Nossa IA está analisando os dados para geração dos dashboards de BI. Este processo pode demorar devido à quantidade de clientes em fila de processamento e análise. Tente novamente daqui alguns dias.
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="stylus" src="../assets/bi.styl"></style>
